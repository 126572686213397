import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { HomeRoutes } from './home.routes';
import { MatProgressBarModule, MatInputModule, MatTooltipModule, MatDialogModule } from '@angular/material';
//import { PushNotificationsModule } from 'ng-push';
import { ToastrModule } from 'ngx-toastr';
import { ToastCustom } from './toast.custom';
import { WidgetModule} from "../shared/widgets/widget.module";
import { Effects as LivestreamEffects, Reducers as LivestreamReducers } from './livestream/store';
import { Effects as VideowallEffects, Reducers as VideowallReducers } from './videowall/store';
import { Effects as CountingEffects, Reducers as CountingReducers } from './counting/store';
import { Effects as DeviceEffects, Reducers as DeviceReducers } from './devices/store';
import { Effects as ProfileEffects, Reducers as ProfileReducers } from './profile/store';
import { Effects as SitesEffects, Reducers as SitesReducers } from './sites/store';
import { Effects as GroupsEffects, Reducers as GroupsReducers } from './groups/store';
import { Effects as RecentRecordingsEffects, Reducers as RecentRecordingsReduces } from './recent-recordings/store';
import { Effects as AccountsEffects, Reducers as AccountsReducers } from './accounts/store';
import { Effects as RolesEffects, Reducers as RolesReducers } from './roles/store';
import { Effects as LocationsEffects, Reducers as LocationsReducers } from './locations/store';
import { Effects as SubscriptionEffects, Reducers as SubscriptionReducers } from './subscription/store';
import { Effects as ChannelsEffects, Reducers as ChannelsReducers } from './channels/store';
import { Effects as NotificationsEffects, Reducers as NotificationsReducers } from './alerts/store';
import { Effects as TasksEffects, Reducers as TasksReducers } from './tasks/store';
import { Effects as WatchlistEffects, Reducers as WatchlistReducers } from './watchlist/store';
import { Effects as AnalyticsEffects, Reducers as AnalyticsReducers } from './analytics/store';
import { Effects as MediaEffects, Reducers as MediaReducers } from './media/store';
import { Effects as DayEffects, Reducers as DayReducers } from './day/store';
import { Effects as DashboardEffects, Reducers as DashboardReducers } from './dashboard/store';
import { Effects as IOsEffects, Reducers as IOsReducers } from './io/store';
import { Effects as HomeEffects, Reducers as HomeReducers } from './store';
import {
  MqttModule,
  MqttService,
  IMqttServiceOptions
} from 'ngx-mqtt';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false
};

export function mqttServiceFactory() {
  return new MqttService(MQTT_SERVICE_OPTIONS);
}

@NgModule({
  imports: [
    CommonModule,
    HomeRoutes.getAppRoutes(),
    MatProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    ToastrModule.forRoot({
      toastComponent: ToastCustom // added custom toast!
    }),
    //PushNotificationsModule,
    FormsModule,
    ReactiveFormsModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    WidgetModule
  ],
  declarations: HomeRoutes.getDeclarations(),
  entryComponents: HomeRoutes.getEntryComponents()
})
export class HomeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: HomeRootModule,
      providers: HomeRoutes.getProviders(),
    };
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    HomeModule,
    StoreModule.forFeature('livestream', LivestreamReducers),
    StoreModule.forFeature('videowall', VideowallReducers),
    StoreModule.forFeature('counting', CountingReducers),
    StoreModule.forFeature('devices', DeviceReducers),
    StoreModule.forFeature('sites', SitesReducers),
    StoreModule.forFeature('groups', GroupsReducers),
    StoreModule.forFeature('recordings', RecentRecordingsReduces),
    StoreModule.forFeature('accounts', AccountsReducers),
    StoreModule.forFeature('roles', RolesReducers),
    StoreModule.forFeature('locations', LocationsReducers),
    StoreModule.forFeature('profile', ProfileReducers),
    StoreModule.forFeature('subscription', SubscriptionReducers),
    StoreModule.forFeature('notifications', NotificationsReducers),
    StoreModule.forFeature('tasks', TasksReducers),
    StoreModule.forFeature('watchlist', WatchlistReducers),
    StoreModule.forFeature('analytics', AnalyticsReducers),
    StoreModule.forFeature('channels', ChannelsReducers),
    StoreModule.forFeature('media', MediaReducers),
    StoreModule.forFeature('day', DayReducers),
    StoreModule.forFeature('home', HomeReducers),
    StoreModule.forFeature('dashboard', DashboardReducers),
    StoreModule.forFeature('ios', IOsReducers),
    EffectsModule.forFeature([
      LivestreamEffects,
      VideowallEffects,
      CountingEffects,
      DeviceEffects,
      MediaEffects,
      DayEffects,
      NotificationsEffects,
      ChannelsEffects,
      HomeEffects,
      ProfileEffects,
      SitesEffects,
      GroupsEffects,
      RecentRecordingsEffects,
      AccountsEffects,
      RolesEffects,
      LocationsEffects,
      SubscriptionEffects,
      TasksEffects,
      WatchlistEffects,
      AnalyticsEffects,
      DashboardEffects,
      IOsEffects
    ])
  ],
})
export class HomeRootModule {}
