import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material';
import { WallRoutes } from './wall.routes';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Reducers, Effects } from './store';
import { WidgetModule } from "../shared/widgets/widget.module";
import {
  MqttModule,
  MqttService,
  IMqttServiceOptions
} from 'ngx-mqtt';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false
};

export function mqttServiceFactory() {
  return new MqttService(MQTT_SERVICE_OPTIONS);
}


@NgModule({
  imports: [
    CommonModule,
    WallRoutes.getAppRoutes(),
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    WidgetModule,
    DeviceDetectorModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
  ],
  declarations: WallRoutes.getDeclarations()
})
export class WallModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootWallModule,
      providers: WallRoutes.getProviders(),
    };
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forFeature('wall', Reducers),
    WallModule,
    EffectsModule.forFeature([Effects])
  ],
})
export class RootWallModule {}
