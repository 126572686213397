import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { GestureConfig } from '@angular/material';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CustomRouterStateSerializer } from './shared/utils';
import { AppRoutes } from './app.routes';
import { reducers, metaReducers } from './app.reducer';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { WallModule } from './wall/wall.module';
import { ApiService } from './shared/api.service';
import { LoggingService } from './shared/logging.service';
import { MqttService } from './shared/mqtt.service';
import { PipeModule } from './shared/pipes/pipe.module';
import { ToastService } from "./shared/toast.service";

// Disale sentry
/*Raven.config(sentryConfig.url).install();
export class RavenErrorHandler implements ErrorHandler {
  handleError(err:any) : void {
    Raven.captureException(err.originalError);
  }
}*/

export function provideErrorHandler() {
  if (environment.production) {
    //return new RavenErrorHandler();
    return new ErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: AppRoutes.getDeclarations(),
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([]),
    AppRoutes.getAppRoutes(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    HomeModule.forRoot(),
    LoginModule.forRoot(),
    WallModule.forRoot(),
    PipeModule.forRoot()
  ],
  providers: [
    ApiService,
    LoggingService,
    ToastService,
    MqttService,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: ErrorHandler, useFactory: provideErrorHandler }
  ],
  bootstrap: [AppRoutes.getBootstrapComponent()]
})
export class AppModule {}
