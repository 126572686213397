import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from "@angular/common";
import {MatTableModule, MatPaginatorModule, MatSelectModule, MatTooltipModule} from '@angular/material';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NgxGeoautocompleteModule} from "ngx-geoautocomplete-v2";
import {NgxStripeModule} from "ngx-stripe";
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {stripeConfig} from '../../../environments/environment';
import {RouterModule} from '@angular/router';
import {TableField} from "./table/table.component";
import {ButtonField} from "./button/button.component";
import {Checkbox} from "./checkbox/checkbox.component";
import {SearchField} from "./search/search.component";
import {SliderField} from "./slider/slider.component";
import {InputField} from "./input/input.component";
import {SelectInputField} from "./selectinput/selectinput.component";
import {LanguageSelect} from './languageselect/languageselect.component';
import {StreamComponent} from "./stream/stream.component";
import {BlinkingDot} from "./stream/block/blinkingdot/blinkingdot.component";
import {MqttStream} from "./stream/block/mqttstream/mqttstream.component";
import {WebRTCStream} from "./stream/block/webrtcstream/webrtcstream.component";
import {PTZ} from "./stream/block/ptz/ptz.component";
import {Talk} from "./stream/block/talk/talk.component";
import {Io} from "./stream/block/io/io.component";
import {PTZPreset} from "./stream/block/ptzpreset/ptzpreset.component";
import {Record} from "./stream/block/record/record.component";
import {Block} from "./block/block.component";
import {BlockHeader} from "./block-header/block-header.component";
import {BlockStatus} from "./block-status/block-status.component";
import {BlockBody} from "./block-body/block-body.component";
import {BlockFooter} from "./block-footer/block-footer.component";
import {BlockButton} from "./block-button/block-button.component";
import {KPI} from "./kpi/kpi.component";
import {Card} from "./card/card.component";
import {SvgIcon} from "./svg-icon/svg-icon.component";
import {VerifiedBadge} from "./table/blocks/verified/verified.component";
import {Badge} from "./badge/badge.component";
import {Breadcrumb} from "./breadcrumb/breadcrumb.component";
import {ProgressBar} from "./progressbar/progressbar.component";
import {MultiToggle} from "./multitoggle/multitoggle.component";
import {GridToggle} from "./gridtoggle/gridtoggle.component";
import {RadioSelect} from "./radioselect/radioselect.component";
import {MultiSelect} from "./multiselect/multiselect.component";
import {RegionSelect} from "./regionselect/regionselect.component";
import {DatePicker} from "./datepicker/datepicker.component";
import {DateInputField} from "./dateinput/dateinput.component";
import {InfoBox} from "./infobox/infobox.component";
import {Modal} from "./modal/modal.component";
import {ModalHeader} from "./modal/blocks/modalheader/modalheader.component";
import {ModalBody} from "./modal/blocks/modalbody/modalbody.component";
import {ModalActions} from "./modal/blocks/modalactions/modalactions.component";
import {CameraPicker} from "./camerapicker/camerapicker.component";
import {SitePicker} from "./sitepicker/sitepicker.component";
import {AddressField} from "./address/address.component";
import {GroupBadge} from "./groupbadge/groupbadge.component";
import {SiteBadge} from "./sitebadge/sitebadge.component";
import {CameraBadge} from "./camerabadge/camerabadge.component";
import {SiteBullet} from "./sitebullet/sitebullet.component";
import {CameraBullet} from "./camerabullet/camerabullet.component";
import {Placeholder} from "./placeholder/placeholder.component";
import {DevicesList} from "./devices-list/devices-list.component";
import {DevicesDetail} from "./devices-list/devices-detail/devices-detail.component";
import {DatasetList} from "./dataset-list/dataset-list.component";
import {DatasetDetail} from "./dataset-list/dataset-detail/dataset-detail.component";
import {SampleList} from './sample-list/sample-list.component';
import {SampleDetail} from "./sample-list/sample-detail/sample-detail.component";
import {AccountsList} from "./accounts-list/accounts-list.component";
import {VideowallList} from "./videowall-list/videowall-list.component";
import {RolesList} from "./roles-list/roles-list.component";
import {TasksList} from "./tasks-list/tasks-list.component";
import {TasksLine} from "./tasks-list/blocks/tasks-line.component";
import {InlineButtonField} from "./inlinebutton/inlinebutton.component";
import {CreditCardBox} from "./creditcardbox/creditcardbox.component";
import {CreateTask} from "./createtask/createtask.component";
import {ExportMedia} from "./exportmedia/exportmedia.component";
import {AccountsPicker} from "./accountspicker/accountspicker.component";
import {ChannelsPicker} from "./channelspicker/channelspicker.component";
import {ClassificationPicker} from "./classificationpicker/classificationpicker.component";
import {FloorplanSwitcher} from "./floorplan-switcher/floorplan-switcher.component";
import {FloorplanCameraPlacer} from "./floorplan-camera-placer/floorplan-camera-placer.component";
import {VideoJsComponent} from "./video-js/video-js.component";
import {CanvasComponent} from "./canvas/canvas.component";
import {LabelerComponent} from "./labeler/labeler.component";
import {ItemPicker} from "./itempicker/itempicker.component";
import {IOPicker} from "./iopicker/iopicker.component";
import {WatchList} from './watch-list/watch-list.component';
import {WatchLine} from './watch-list/blocks/watch-line.component';
import {ShareComponent} from './share/share.component';
import {Tags} from './tags/tags.component';
import {Status} from './status/status.component';
import {LoadMore} from './loadmore/loadmore.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxGeoautocompleteModule.forRoot(),
    NgxStripeModule.forRoot(stripeConfig.publishableKey),
    NgxSliderModule,
  ],
  declarations: [
    TableField,
    ButtonField,
    SearchField,
    InputField,
    SelectInputField,
    LanguageSelect,
    SliderField,
    VerifiedBadge,
    Badge,
    SiteBadge,
    StreamComponent,
    MqttStream,
    WebRTCStream,
    PTZ,
    PTZPreset,
    Record,
    Talk,
    Io,
    BlinkingDot,
    Breadcrumb,
    Block,
    BlockHeader,
    BlockStatus,
    BlockBody,
    BlockFooter,
    BlockButton,
    KPI,
    Card,
    SvgIcon,
    ProgressBar,
    MultiToggle,
    GridToggle,
    RadioSelect,
    MultiSelect,
    RegionSelect,
    DatePicker,
    DateInputField,
    InfoBox,
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    CameraPicker,
    ItemPicker,
    IOPicker,
    AddressField,
    Placeholder,
    DevicesList,
    DevicesDetail,
    DatasetList,
    DatasetDetail,
    SampleList,
    SampleDetail,
    AccountsList,
    VideowallList,
    RolesList,
    InlineButtonField,
    CreditCardBox,
    TasksList,
    TasksLine,
    CreateTask,
    ExportMedia,
    AccountsPicker,
    ChannelsPicker,
    ClassificationPicker,
    SiteBullet,
    CameraBullet,
    CameraBadge,
    GroupBadge,
    SitePicker,
    FloorplanSwitcher,
    FloorplanCameraPlacer,
    VideoJsComponent,
    Checkbox,
    CanvasComponent,
    LabelerComponent,
    WatchList,
    WatchLine,
    ShareComponent,
    Tags,
    Status,
    LoadMore,
  ],
  exports: [
    CommonModule,
    TableField,
    ButtonField,
    SearchField,
    InputField,
    SelectInputField,
    LanguageSelect,
    SliderField,
    VerifiedBadge,
    Badge,
    SiteBadge,
    StreamComponent,
    MqttStream,
    WebRTCStream,
    PTZ,
    PTZPreset,
    Record,
    Talk,
    Io,
    BlinkingDot,
    Breadcrumb,
    Block,
    BlockHeader,
    BlockStatus,
    BlockBody,
    BlockFooter,
    BlockButton,
    KPI,
    Card,
    SvgIcon,
    ProgressBar,
    MultiToggle,
    GridToggle,
    RadioSelect,
    MultiSelect,
    RegionSelect,
    DatePicker,
    DateInputField,
    InfoBox,
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    CameraPicker,
    ItemPicker,
    IOPicker,
    AddressField,
    Placeholder,
    DevicesList,
    DevicesDetail,
    DatasetList,
    DatasetDetail,
    SampleList,
    SampleDetail,
    AccountsList,
    VideowallList,
    RolesList,
    InlineButtonField,
    CreditCardBox,
    TasksList,
    TasksLine,
    CreateTask,
    ExportMedia,
    AccountsPicker,
    ChannelsPicker,
    ClassificationPicker,
    SiteBullet,
    CameraBullet,
    CameraBadge,
    GroupBadge,
    SitePicker,
    FloorplanSwitcher,
    FloorplanCameraPlacer,
    VideoJsComponent,
    Checkbox,
    CanvasComponent,
    LabelerComponent,
    WatchList,
    WatchLine,
    ShareComponent,
    Tags,
    Status,
    LoadMore,
  ],
  providers:[ DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } ],
})
export class WidgetModule { }
