import {Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromTasks from "../../../home/tasks/store";
import * as Tasks from "../../../home/tasks/store/actions";
import * as fromAccounts from "../../../home/accounts/store";
import * as Accounts from "../../../home/accounts/store/actions";
import {Md5} from "ts-md5";
import {DatePipe} from "@angular/common";
const moment = require('moment');

@Component({
  selector: "CreateTask",
  templateUrl: './createtask.component.html',
  styleUrls: ['./createtask.component.scss'],
})
export class CreateTask implements OnInit, OnDestroy, OnChanges  {

  @Input() showText: boolean = true;
  @Input() error: string;
  @Input() sequenceId: any
  @Input() mediaId: any
  @Input() cameraId: any
  @Input() media: any
  @Input() task: any
  @Input() mode: string = 'add';

  public gravatarSize: number = 40;
  public accounts$ = this.store.select(fromAccounts.getAccounts);
  public accountsError$ = this.store.select(fromAccounts.getError);
  public accountsSubscription;
  public accountsErrorSubscription;
  public accounts;
  public accountsPickerItems: any = [];
  public selectedAccounts: any = [];
  public selectedAccountsLabels: any = [];
  public mediaDate: string = "";
  public mediaTime: string = "";

  public taskForm: FormGroup;
  public taskUpdated: boolean = false;
  public taskFormInvalidControls: any[] = [];

  constructor(private fb: FormBuilder,
              private store: Store<fromTasks.State>,
              public datePipe: DatePipe){

    this.taskForm = this.fb.group({
      title: ['', [Validators.required]],
      notes: ['', [Validators.required]],
    });

    this.onChangeAccounts = this.onChangeAccounts.bind(this);
    this.findInvalidControls = this.findInvalidControls.bind(this);
  }

  ngOnInit(){
    this.store.dispatch(new Accounts.RequestAccounts());
    this.accountsSubscription = this.accounts$.subscribe(accounts => {
      this.accounts = accounts;
      this.accountsPickerItems = this.accounts.map(c => {
        return {
          text: c.firstname + " " + c.lastname,
          email: c.email,
          username: c.username,
          role: c.role,
          value: c.id
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.media) {
      this.media = changes.media.currentValue;
      if (this.media) {
        const dateTime = moment(this.media.timestamp * 1000).toDate();
        this.mediaDate = this.datePipe.transform(dateTime, 'MMMM dd, EEEE');
        this.mediaTime = this.datePipe.transform(dateTime, 'HH:MM:SS');
      }
    }
  }

  onChangeAccounts(accounts, labels){
    this.selectedAccounts = accounts;
    this.selectedAccountsLabels = labels;
  }

  getGravatar(email) {
    return 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?s=' + this.gravatarSize + '&d=mm';
  }

  findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  addUpdateTask() {
    this.taskUpdated = true;
    this.taskFormInvalidControls = this.findInvalidControls(this.taskForm);
    if(this.taskForm.valid && this.selectedAccounts.length > 0) {
      if (this.mode === 'add') {
        
        const exportMedia = [{
          timestamp: this.media.timestamp,
          key: this.media.metadata.key,
          camera_id: this.media.metadata.camera_id,
          provider: this.media.metadata.provider,
          source: this.media.metadata.source,
          spriteFile: this.media.metadata.spriteFile,
          spriteProvider: this.media.metadata.spriteProvider,
          thumbnailFile: this.media.metadata.thumbnailFile,
          thumbnailProvider: this.media.metadata.thumbnailProvider,
        }];

        const taskData = {
          title: this.taskForm.get('title').value,
          notes: this.taskForm.get('notes').value,
          assignees: this.selectedAccounts,
          assignees_profile: this.selectedAccountsLabels,
          mediaId: this.mediaId,
          sequenceId: this.sequenceId,
          export_status: "new",
          export_files: exportMedia,
          cameras: [this.media.metadata.camera_id],
        }
        this.store.dispatch(new Tasks.AddTask(taskData))
        return true;
      }
    }
    return false;
  }

  ngAfterViewInit(){
  }

  ngOnDestroy(){
    if(this.accountsSubscription){
      this.accountsSubscription.unsubscribe();
    }
  }
}
