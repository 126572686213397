import {Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import { v4 as uuidv4 } from "uuid";
import {IMqttMessage, MqttService as MqttService2} from "ngx-mqtt";
import * as fromDevices from '../../../../../home/devices/store';
import {
  MqttService,
} from '../../../../mqtt.service';
import {kerberosConfig} from "../../../../../../environments/environment";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: "WebRTCStream",
  templateUrl: './webrtcstream.component.html',
  styleUrls: ['./webrtcstream.component.scss'],
})
export class WebRTCStream implements OnInit, OnDestroy  {

  @Input() deviceKey: string;
  @Input() cloudKey: string;
  @Input() changeStatus: any;
  @Input() encrypted: boolean = false;
  @Input() profile: any = null;
  @ViewChild('target', { static: false }) target: ElementRef;

  public config: any;
  public turnServer: any = kerberosConfig.turnServer;
  public pc: any;
  public cuuid: any;
  public candidatesQueue: any;

  public subscription: any = null;
  public subscriptionICE: any = null;
  public sendChannel: any;

  public status: string = 'pending';
  public livestream;
  public liveStreamObservable;
  public liveLegacyStreamObservable;
  public pollingObservables: Array<any> = [];
  public livestreamStarted: boolean = false;

  public eventsSubscription: Subscription;
  public events$ = this.store.select(fromDevices.getEvents);
  public events: any;

  constructor(
    private store: Store<fromDevices.State>,
    private mqttService: MqttService,
    @Inject('mqttNew') private _mqttService: MqttService2) {}

    ngOnInit(){
      this.updateStatus('pending');
      this.handleNegotiationNeededEvent = this.handleNegotiationNeededEvent.bind(this);
      this.mute = this.mute.bind(this);
    }

    ngAfterViewInit(){
      this.startStream();
    }

    updateStatus(status) {
      this.status = status;
      this.changeStatus(status);
    }

    startStream() {

      this.cuuid = uuidv4();
      this.candidatesQueue = [];

      const stunServer = "stun:"+ this.turnServer.split("turn:")[1];
      this.config = {
        //iceTransportPolicy: 'relay',
        iceServers: [
          {
            urls: [stunServer],
          },
          {
            urls: [this.turnServer],
            username: kerberosConfig.turnUsername,
            credential: kerberosConfig.turnPassword,
          }
        ],
      };

      // Setup WebRTC component.
      this.pc = new RTCPeerConnection(this.config);

      // Set the maximum number of packets in the jitter buffer to 100
      // TODO: if possible

      this.pc.onnegotiationneeded = this.handleNegotiationNeededEvent;
      this.pc.oniceconnectionstatechange = e => {
        this.status = this.pc.iceConnectionState;
        this.changeStatus(this.status)
        if(this.status === 'disconnected') {
          this.disconnectStream();
          const interval = setInterval(() => {
            if(this.status === "checking") {
              this.startStream()
            } else if(this.status === "connected") {
              this.changeStatus(this.status)
              clearInterval(interval)
            }
          }, 3000);
        }
      }

      this.pc.ontrack = (event) => {
        this.target.nativeElement.srcObject = event.streams[0];
      };

      this.eventsSubscription = this.events$.subscribe((e) => {
        // Filter on the id of the device
        const events = e.filter((event) => event.key === this.deviceKey);
        if(events && events.length > 0) {
          const event = events[0];
          if (event.action === 'receive-hd-answer' && this.pc) {
            // Make sure we are working on the correct session.
            const session_id = event.value.session_id;
            if(session_id && session_id !== this.cuuid) {
              return;
            }
            const answer = event.value.sdp.toString();
            // Convert from base64
            const answerString = atob(atob(answer));
            this.pc.setRemoteDescription(
              new RTCSessionDescription({
                type: "answer",
                sdp: answerString,
              })
            ).then(() => {
              for(let i = 0; i < this.candidatesQueue.length; i++){
                this.pc.addIceCandidate(this.candidatesQueue[i]);
              }
            });
          } else if(event.action === 'receive-hd-candidates' && this.pc){
            // Make sure we are working on the correct session.
            const session_id = event.value.session_id;
            if(session_id && session_id !== this.cuuid) {
              return;
            }

            const candidate = JSON.parse(event.value.candidate.toString());
            if(this.pc.currentRemoteDescription) {
              this.pc.addIceCandidate(candidate);
            } else {
              this.candidatesQueue.push(candidate);
            }
          }
        }
      });

      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // TODO GET RID OF IT, but we can't do it now because we have to support old agents
      // Legacy
      this.subscriptionICE = this._mqttService.observe(`${this.deviceKey}/${this.cuuid}/candidate/edge`).subscribe((message: IMqttMessage) => {
        const candidate = JSON.parse(message.payload.toString());
        if(this.pc.currentRemoteDescription) {
          this.pc.addIceCandidate(candidate);
        } else {
          this.candidatesQueue.push(candidate);
        }
      });

      this.pc.onicecandidate = (event) => {
        if (event.candidate) {
          // New Method single observable
          // We should get rid of the legacy methods as mentioned below.
          const topic = "kerberos/agent/" + this.cloudKey;
          const payload = {
            action: "receive-hd-candidates",
            device_id: this.deviceKey,
            value: {
              timestamp: Math.floor(Date.now() / 1000),
              session_id: this.cuuid,
              candidate: event.candidate.candidate,
            }
          };
          this.mqttService.publish(topic, payload, this.encrypted)

          // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          // TODO GET RID OF IT, but we can't do it now because we have to support old agents
          // Legacy
          this._mqttService.unsafePublish(`candidate/cloud`, JSON.stringify({
            candidate: event.candidate.candidate,
            cuuid: this.cuuid,
            cloud_key: this.deviceKey,
          }), {qos: 0});
          this._mqttService.unsafePublish(`${this.deviceKey}/${this.cuuid}/candidate/cloud`, JSON.stringify(event.candidate.candidate), {qos: 0});
        } else {
          // All ICE candidates have been sent
        }
      }

      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // TODO GET RID OF IT, but we can't do it now because we have to support old agents
      // Legacy
      this.subscription = this._mqttService.observe(`${this.deviceKey}/${this.cuuid}/answer`).subscribe((message: IMqttMessage) => {
        this.pc.setRemoteDescription(
          new RTCSessionDescription({
            type: "answer",
            sdp: atob(message.payload.toString()),
          })
        ).then(() => {
          for(let i = 0; i < this.candidatesQueue.length; i++){
            this.pc.addIceCandidate(this.candidatesQueue[i]);
          }
        });
      });

      // This will fire the whole process!
      this.pc.addTransceiver("video", {
        direction: "sendrecv",
      });
      this.pc.addTransceiver("audio", {
        direction: "sendrecv",
      });
    }

    mute(isMuted: boolean) {
      const video = this.target.nativeElement;
      if(isMuted) {
        video.muted = true;
        video.volume = 0;
      } else {
        video.muted = false;
        video.volume = 1;
      }
    }

    handleNegotiationNeededEvent() {
      return this.pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
        iceRestart: true,
      }).then(offer => {
        return this.pc.setLocalDescription(offer);
      }).then(() => {
        // New Method single observable
        // We should get rid of the legacy methods as mentioned below.
        const topic = "kerberos/agent/" + this.cloudKey;
        const payload = {
          action: "request-hd-stream",
          device_id: this.deviceKey,
          value: {
            timestamp: Math.floor(Date.now() / 1000),
            session_id: this.cuuid,
            session_description: btoa(this.pc.localDescription.sdp),
          }
        };
        this.mqttService.publish(topic, payload, this.encrypted)

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // TODO GET RID OF IT, but we can't do it now because we have to support old agents
        // Legacy
        const myjson = {
          cuuid: this.cuuid,
          sdp: btoa(this.pc.localDescription.sdp),
        };
        this._mqttService.unsafePublish(`${this.deviceKey}/register`, JSON.stringify(myjson), {qos: 0});


      }).catch(error => console.log(error));
    }

    disconnectStream() {
      if(this.sendChannel){
        this.sendChannel.close();
      }
      if(this.pc){
        this.pc.close();
      }
      if(this.subscription) {
        this.subscription.unsubscribe();
      }
      if(this.subscriptionICE) {
        this.subscriptionICE.unsubscribe();
      }
      if (this.eventsSubscription) {
        this.eventsSubscription.unsubscribe();
      }
      this.updateStatus('checking');
    }

    ngOnDestroy() {
      this.disconnectStream();
    }
}
