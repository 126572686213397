import {Component, Input} from '@angular/core';
import {Md5} from "ts-md5";

@Component({
  selector: "VideowallList",
  templateUrl: './videowall-list.component.html',
  styleUrls: ['./videowall-list.component.scss'],
})
export class VideowallList {

  @Input() user: any;
  @Input() videowalls: any;
  @Input() editAccount: any
  @Input() deleteAccount: any

  public gravatarSize: number = 40;

  public getGravatar(email) {
    return 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?s=' + this.gravatarSize + '&d=mm';
  }

  public openVideowall(videowallId, videowall) {
      const { fingerprint } = videowall
      const url = `http://localhost:4200/wall/${fingerprint}`; // replace with your actual URL
      window.open(url, '_blank');
  }

  public trackAccount(index: number, account: any) {
    return account.id;
  }
}
